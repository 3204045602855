export const postingServices = {
  uploadImages,
  post,
  payment,
  removePosting,
  contactForm,
  deleteImage
};

const hostname = "findmyroom.us";

async function uploadImages(images, jT) {
  let formData = new FormData();

  for (var i = 0; i < images.length; i++) {
    formData.append("media", images[i]);
  }

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + jT,
      Accept: "application/json"
    },
    body: formData
  };

  return await fetch(
    `https://${hostname}/api/v1/properties/images`,
    requestOptions
  ).then(handleResponse);
}

function post(postingDetails, jT, postingId) {
  if(postingDetails.address) {
    delete postingDetails.address;
  }

  const requestOptions = {
    method: postingId ? "PATCH" : "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    headers: {
      Authorization: "Bearer " + jT,
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify(postingDetails)
  };

  if (postingId) {
    return fetch(
      `https://${hostname}/api/v1/properties/${postingId && postingId}`,
      requestOptions
    ).then(handleResponse);
  } else {
    return fetch(`https://${hostname}/api/v1/properties`, requestOptions).then(
      handleResponse
    );
  }
}

function payment(postingId, plan, jT) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    headers: {
      Authorization: "Bearer " + jT,
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify({
      plan: plan
    })
  };

  return fetch(
    `https://${hostname}/api/v1/properties/${postingId}/payments/request`,
    requestOptions
  ).then(handleResponse);
}

function removePosting(postindId, jT) {
  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    headers: {
      Authorization: "Bearer " + jT,
      "Content-Type": "application/json; charset=utf-8"
    }
  };

  return fetch(
    `https://${hostname}/api/v1/properties/${postindId}`,
    requestOptions
  ).then(handleResponse);
}

function contactForm(name, email, phoneNumber, message) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify({
      name,
      email,
      phoneNumber,
      message
    })
  };

  return fetch(
    `https://${hostname}/api/v1/website/contact`,
    requestOptions
  ).then(handleResponse);
}

function deleteImage(imageURL, postingId, jT) {
  const requestOptions = {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    headers: {
      Authorization: "Bearer " + jT,
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify({
      url: imageURL
    })
  };

  return fetch(
    `https://${hostname}/api/v1/properties/${postingId}/images`,
    requestOptions
  ).then(handleResponse);
}

/* function getAccessToken(refreshToken) { 
    const requestOptions = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify({
            "refreshToken": refreshToken
        })
    };

    return fetch(`https://${hostname}/auth/getAccessToken`, requestOptions)
        .then(handleResponse)
} 
    const formData = new FormData();
    for(var i=0; i<images.length; i++){
        formData.append('file', images[i]);
    }*/

function handleResponse(response) {
  return response.json().then(json => {
    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        return Promise.reject("403");
      }

      const error = (json && json.message) || response.statusText;
      return Promise.reject(error);
    }
    return json;
  });
}
