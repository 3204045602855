import { postingConstants } from "../_constants";
import { authConstants } from "../_constants";
import { postingServices } from "../_services";
import { authServices } from "../_services";
import imageCompression from "browser-image-compression";

export const postingActions = {
  setPostingDetails,
  setPropertyAddress,
  setPropertyPrice,
  setPropertyCoordinates,
  posting,
  removePosting,
  contactForm,
  editPosting,
  deleteImage,
  setPlan,
  resetPostingDetails
};

async function compressImages(images) {
  var options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };
  var newImages = [];
  for (var img in images) {
    await imageCompression(images[img], options)
      .then(function (compressedFile) {
        newImages.push(compressedFile);
      })
      .catch(function (error) {
      });
  }
  if (images.length === newImages.length) {
    images = newImages;
  }

  return images;
}

function posting(
  postingDetails,
  images,
  jT,
  userDetails,
  plan,
  postingId,
  paidPlan
) {
  return async dispatch => {
    dispatch(request());
    const newImages = await compressImages(images);
    postingServices.uploadImages(newImages, jT).then(
      response => {
        dispatch(success());
        let urls = [];
        if (postingDetails["images"]) {
          for (var i = 0; i < postingDetails["images"].length; i++) {
            delete postingDetails["images"][i]["_id"];
          }
          for (var i = 0; i < response.data.urls.length; i++) {
            postingDetails["images"].push({ url: response.data.urls[i] });
          }
        } else {
          for (var i = 0; i < response.data.urls.length; i++) {
            urls.push({ url: response.data.urls[i] });
          }
          postingDetails["images"] = urls;
        }
        postingDetails.contact.person = userDetails.name;
        userDetails.email && postingDetails.contact.email.push(userDetails.email);
        dispatch(request1());
        postingServices.post(postingDetails, jT, postingId).then(
          response1 => {
            if (["basic", paidPlan].includes(plan)) {
              dispatch(success1("basic"));
            } else {
              dispatch(paymentRequest(plan));
              postingServices.payment(response1.data._id, plan, jT).then(
                paymentResponse => {
                  window.location = paymentResponse.data.paymentUrl;
                },
                error2 => {
                  dispatch(paymentFailure());
                }
              );
            }
          },
          error1 => {
            dispatch(failure1());
          }
        );
      },
      error => {
        dispatch(failure());
        if (error === "403") {
          dispatch(refreshRequest());
          authServices.refreshToken(userDetails.refreshToken).then(
            response => {
              dispatch(refreshSuccess(response.data.jT, response.data.user));
              var newjT = response.data.jT;
              dispatch(request());
              postingServices.uploadImages(images, newjT).then(
                response1 => {
                  dispatch(success());
                  let urls = [];
                  if (postingDetails["images"]) {
                    for (var i = 0; i < response1.data.urls.length; i++) {
                      postingDetails["images"].push({
                        url: response1.data.urls[i]
                      });
                    }
                  } else {
                    for (var i = 0; i < response1.data.urls.length; i++) {
                      urls.push({ url: response1.data.urls[i] });
                    }
                    postingDetails["images"] = urls;
                  }
                  postingDetails.contact.person = userDetails.name;
                  postingDetails.contact.email.push(
                    userDetails.email
                  );
                  dispatch(request1());
                  postingServices.post(postingDetails, newjT, postingId).then(
                    response2 => {
                      if (["basic", paidPlan].includes(plan)) {
                        dispatch(success1("basic"));
                      } else {
                        dispatch(paymentRequest(plan));
                        postingServices
                          .payment(response2.data._id, plan, newjT)
                          .then(
                            paymentResponse => {
                              window.location = paymentResponse.data.paymentUrl;
                            },
                            error3 => {
                              dispatch(paymentFailure());
                            }
                          );
                      }
                    },
                    error2 => {
                      dispatch(failure1());
                    }
                  );
                },
                error1 => {
                  dispatch(failure());
                  let urls = [];
                  if (postingDetails["images"]) {
                    for (var i = 0; i < response.data.urls.length; i++) {
                      postingDetails["images"].push({
                        url: response.data.urls[i]
                      });
                    }
                  } else {
                    for (var i = 0; i < response.data.urls.length; i++) {
                      urls.push({ url: response.data.urls[i] });
                    }
                    postingDetails["images"] = urls;
                  }
                  postingDetails.contact.person = userDetails.name;
                  postingDetails.contact.email.push(
                    userDetails.email
                  );
                  dispatch(request1());
                  postingServices.post(postingDetails, newjT, postingId).then(
                    response1 => {
                      if (["basic", paidPlan].includes(plan)) {
                        dispatch(success1("basic"));
                      } else {
                        dispatch(paymentRequest(plan));
                        postingServices
                          .payment(response1.data._id, plan, newjT)
                          .then(
                            paymentResponse => {
                              window.location = paymentResponse.data.paymentUrl;
                            },
                            error3 => {
                              dispatch(paymentFailure());
                            }
                          );
                      }
                    },
                    error2 => {
                      dispatch(failure1());
                    }
                  );
                }
              );
            },
            error0 => {
              dispatch(refreshFailure());
            }
          );
        }
      }
    );
  };

  function request() {
    return { type: postingConstants.UPLOAD_IMAGES_REQUEST };
  }
  function success(data) {
    return { type: postingConstants.UPLOAD_IMAGES_SUCCESS, data };
  }
  function failure(error) {
    return { type: postingConstants.UPLOAD_IMAGES_FAILURE, error };
  }
  function refreshRequest() {
    return { type: authConstants.REFRESH_REQUEST };
  }
  function refreshSuccess(jT, userDetails) {
    return { type: authConstants.REFRESH_SUCCESS, jT, userDetails };
  }
  function refreshFailure() {
    return { type: authConstants.REFRESH_FAILURE };
  }
  function request1() {
    return { type: postingConstants.POSTING_REQUEST };
  }
  function success1(plan) {
    return { type: postingConstants.POSTING_SUCCESS, plan };
  }
  function failure1(error) {
    return { type: postingConstants.POSTING_FAILURE, error };
  }
  function paymentRequest(plan) {
    return { type: postingConstants.PAYMENT_REQUEST, plan };
  }
  function paymentFailure(error) {
    return { type: postingConstants.PAYMENT_FAILURE, error };
  }
}

function setPostingDetails(detailName, detailValue) {
  return dispatch => {
    if (detailName === "images") {
      dispatch(images(detailValue));
    } else {
      dispatch(success(detailName, detailValue));
    }
  };
  function images(detailValue) {
    return { type: postingConstants.SET_IMAGES, detailValue };
  }
  function success(detailName, detailValue) {
    return {
      type: postingConstants.SET_POSTING_DETAILS,
      detailName,
      detailValue
    };
  }
}

function resetPostingDetails() {
  return dispatch => {
    dispatch(success());

  }; function success() {
    return {
      type: postingConstants.RESET_POSTING_DETAILS,
    };
  }
}

function removePosting(postingId, jT, userDetails) {
  return dispatch => {
    dispatch(request());
    postingServices.removePosting(postingId, jT).then(
      response => {
        dispatch(success(postingId));
      },
      error => {
        if (error == "403") {
          authServices
            .refreshToken(userDetails.refreshToken)
            .then(response1 => {
              dispatch(refreshSuccess(response1.data.jT, response1.data.user));
              var newjT = response1.data.jT;
              postingServices.removePosting(postingId, newjT).then(
                response2 => {
                  dispatch(success(postingId));
                },
                error => {
                  dispatch(failure(error.toString()));
                }
              );
            });
        }
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: postingConstants.DELETE_POSTING_REQUEST };
  }
  function success(message) {
    return { type: postingConstants.DELETE_POSTING_SUCCESS, message };
  }
  function failure(deletePosting) {
    return { type: postingConstants.DELETE_POSTING_FAILURE, deletePosting };
  }
  function refreshSuccess(jT, userDetails) {
    return { type: authConstants.REFRESH_SUCCESS, jT, userDetails };
  }
}

function setPropertyAddress(address) {
  return dispatch => {
    dispatch(success(address));
  };
  function success(address) {
    return { type: postingConstants.SET_PROPERTY_ADDRESS, address };
  }
}

function setPropertyCoordinates(lat, lng) {
  var latlang = [];
  latlang.push(lat);
  latlang.push(lng);
  return dispatch => {
    dispatch(success(latlang));
  };
  function success(latlang) {
    return { type: postingConstants.SET_PROPERTY_ADDRESS_CORDINATES, latlang };
  }
}

function setPropertyPrice(price) {
  return dispatch => {
    dispatch(success(price));
  };
  function success(price) {
    return { type: postingConstants.SET_PROPERTY_PRICE, price };
  }
}

function contactForm(name, email, phoneNumber, message) {
  return dispatch => {
    dispatch(request());
    postingServices.contactForm(name, email, phoneNumber, message).then(
      response => {
        dispatch(success(response));
        alert("Message Sent Succesfully! Please wait for our response.");
      },
      error => {
        dispatch(failure(error.toString()));
        alert("Message could not be sent. Pls try again later");
      }
    );
  };

  function request() {
    return { type: postingConstants.CONTACT_FORM_REQUEST };
  }
  function success(message) {
    return { type: postingConstants.CONTACT_FORM_SUCCESS, message };
  }
  function failure(error) {
    return { type: postingConstants.CONTACT_FORM_FAILURE, error };
  }
}

function editPosting(postingDetails) {
  return dispatch => {
    dispatch(success(postingDetails));
  };
  function success(postingDetails) {
    return { type: postingConstants.SET_EDIT_POSTING_DETAILS, postingDetails };
  }
}

function deleteImage(imageURL, postingId, jT) {
  return dispatch => {
    dispatch(request());
    postingServices.deleteImage(imageURL, postingId, jT).then(
      response => {
        dispatch(success(response, imageURL));
        alert("Image removed");
      },
      error => {
        dispatch(failure(error.toString()));
        alert("Error while removing image");
      }
    );
  };

  function request() {
    return { type: postingConstants.DELETE_IMAGE_REQUEST };
  }
  function success(message, url) {
    return { type: postingConstants.DELETE_IMAGE_SUCCESS, message, url };
  }
  function failure(error) {
    return { type: postingConstants.DELETE_IMAGE_FAILURE, error };
  }
}

function setPlan(plan) {
  return dispatch => {
    dispatch(success(plan));
  };
  function success(plan) {
    return { type: postingConstants.SET_PLAN, plan };
  }
}
