import { listingsServices } from "../_services";
import { listingsConstants } from "../_constants";
import { authServices } from "../_services";
import { authConstants } from "../_constants";

export const listingsActions = {
  getListings,
  setCurrentListing,
  setBounds,
  setPlaceValues,
  setFilters,
  refreshPageCount,
  getMyListings,
  setListings,
  getContactNumber
};

function getListings(
  gender,
  roomType,
  apartmentType,
  amenities,
  furnish,
  minPrice,
  maxPrice,
  sw,
  ne,
  p,
  c
) {
  return dispatch => {
    dispatch(request());
    listingsServices
      .getListings(
        gender,
        roomType,
        apartmentType,
        amenities,
        furnish,
        minPrice,
        maxPrice,
        sw,
        ne,
        p,
        c
      )
      .then(
        response => {
          dispatch(success(response.data));
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };

  function request() {
    return { type: listingsConstants.GET_LISTINGS_REQUEST };
  }
  function success(listings) {
    return { type: listingsConstants.GET_LISTINGS_SUCCESS, listings };
  }
  function failure(error) {
    return { type: listingsConstants.GET_LISTINGS_FAILURE, error };
  }
}

function getContactNumber(postingId, jT, userDetails) {
  return dispatch => {
    dispatch(request());
    listingsServices.getContactNumber(postingId, jT).then(
      response => {
        dispatch(success(response.data.contact));
      },
      error => {
        if (error == "403") {
          authServices
            .refreshToken(userDetails.refreshToken)
            .then(response1 => {
              dispatch(refreshSuccess(response1.data.jT, response1.data.user));
              var newjT = response1.data.jT;
              listingsServices.getContactNumber(postingId, newjT).then(
                response2 => {
                  dispatch(success(response2.data.contact));
                },
                error => {
                  dispatch(failure(error.toString()));
                }
              );
            });
        }
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: listingsConstants.GET_CONTACT_NUMBER_REQUEST };
  }
  function success(contact) {
    return {
      type: listingsConstants.GET_CONTACT_NUMBER_SUCCESS,
      contactNumber: contact.phoneNumber[0],
      contactEmail: contact.email[0],
      contactPerson: contact.person
    };
  }
  function failure(error) {
    return { type: listingsConstants.GET_CONTACT_NUMBER_FAILURE, error };
  }
  function refreshSuccess(jT, userDetails) {
    return { type: authConstants.REFRESH_SUCCESS, jT, userDetails };
  }
}

function setListings(listings) {
  return dispatch => {
    dispatch(success(listings));
  };
  function success(listings) {
    return { type: listingsConstants.GET_LISTINGS_SUCCESS, listings };
  }
}

function getMyListings(jT, userDetails) {
  return dispatch => {
    dispatch(request());
    listingsServices.getMyListings(jT).then(
      response => {
        dispatch(success(response.data));
      },
      error => {
        if (error == "403") {
          authServices
            .refreshToken(userDetails.refreshToken)
            .then(response1 => {
              dispatch(refreshSuccess(response1.data.jT, response1.data.user));
              var newjT = response1.data.jT;
              listingsServices.getMyListings(newjT).then(
                response2 => {
                  dispatch(success(response2.data));
                },
                error => {
                  dispatch(failure(error.toString()));
                }
              );
            });
        }
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: listingsConstants.GET_MY_LISTINGS_REQUEST };
  }
  function success(myListings) {
    return { type: listingsConstants.GET_MY_LISTINGS_SUCCESS, myListings };
  }
  function failure(error) {
    return { type: listingsConstants.GET_MY_LISTINGS_FAILURE, error };
  }
  function refreshSuccess(jT, userDetails) {
    return { type: authConstants.REFRESH_SUCCESS, jT, userDetails };
  }
}

function setCurrentListing(listingIndex) {
  return dispatch => {
    dispatch(success(listingIndex));
  };
  function success(listingIndex) {
    return { type: listingsConstants.SET_LISTING_INDEX, listingIndex };
  }
}

function setBounds(sw, ne, lat, lng) {
  return dispatch => {
    dispatch(success(sw, ne, lat, lng));
  };
  function success(sw, ne, lat, lng) {
    return { type: listingsConstants.SET_BOUNDS, sw, ne, lat, lng };
  }
}

function setPlaceValues(lat, lng, zoom) {
  return dispatch => {
    dispatch(success(lat, lng, zoom));
  };
  function success(lat, lng, zoom) {
    return { type: listingsConstants.SET_PLACE_VALUES, lat, lng, zoom };
  }
}

function setFilters(
  gender,
  roomType,
  apartmentType,
  amenities,
  furnish,
  minPrice,
  maxPrice
) {
  return dispatch => {
    dispatch(
      success(
        gender,
        roomType,
        apartmentType,
        amenities,
        furnish,
        minPrice,
        maxPrice
      )
    );
  };
  function success(
    gender,
    roomType,
    apartmentType,
    amenities,
    furnish,
    minPrice,
    maxPrice
  ) {
    return {
      type: listingsConstants.SET_FILTERS,
      gender,
      roomType,
      apartmentType,
      amenities,
      furnish,
      minPrice,
      maxPrice
    };
  }
}

function refreshPageCount(pageNumber) {
  return dispatch => {
    dispatch(success(pageNumber));
  };
  function success(pageNumber) {
    return { type: listingsConstants.REFRESH_PAGE_COUNT, pageNumber };
  }
}
