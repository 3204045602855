import { routingConstants } from "../_constants";

export const routingActions = {
  setCurrentPage,
  setCurrentStep
};

function setCurrentPage(currentPage) {
  return dispatch => {
    dispatch(success(currentPage));
  };
  function success(currentPage) {
    return { type: routingConstants.SET_CURRENT_PAGE, currentPage };
  }
}

function setCurrentStep(currentStep) {
  return dispatch => {
    dispatch(success(currentStep));
  };
  function success(currentStep) {
    return { type: routingConstants.SET_CURRENT_STEP, currentStep };
  }
}
