export const authServices = {
  signUp,
  verifyOTP,
  requestOTP,
  passwordSignIn,
  refreshToken
};

const hostname = "findmyroom.us";

function signUp(name, email, password) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    //cache: "no-cache",
    //credentials: "omit",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify({
      name,
      email,
      password
    })
  };

  return fetch(`https://${hostname}/api/v1/auth/signup`, requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user.data["jT"]) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    });
}

function passwordSignIn(email, password) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    //cache: "no-cache",
    //credentials: "omit",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify({
      email,
      password
    })
  };

  return fetch(`https://${hostname}/api/v1/auth/login`, requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user.data["jT"]) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    });
}

function refreshToken(rT) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    //cache: "no-cache",
    //credentials: "omit",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify({
      refreshToken: rT
    })
  };

  return fetch(`https://${hostname}/api/v1/auth/refresh`, requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user.data["jT"]) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    });
}

function requestOTP(phoneNumber) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    //cache: "no-cache",
    //credentials: "omit",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify({
      phoneNumber: phoneNumber
    })
  };

  return fetch(
    `https://${hostname}/api/v1/auth/request-otp`,
    requestOptions
  ).then(handleResponse);
}

function verifyOTP(phoneNumber, OTP) {
  const requestOptions = {
    method: "GET",
    mode: "cors",
    headers: {
      //"Authorization": accessToken,
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": "*"
    },
    body: null
  };

  return fetch(
    `https://${hostname}/api/v1/auth/verify-otp?phoneNumber=${phoneNumber}&token=${OTP}`,
    requestOptions
  )
    .then(handleResponse)
    .then(user => {
      if (user.data["jT"]) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    });
}

/* function getAccessToken(refreshToken) {
    const requestOptions = {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify({
            "refreshToken": refreshToken
        })
    };

    return fetch(`https://${hostname}/auth/getAccessToken`, requestOptions)
        .then(handleResponse)
} */

function handleResponse(response) {
  return response.json().then(json => {
    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        localStorage.removeItem("user");
        /* window.location.reload(true); */
      }

      const error = (json && json.message) || response.statusText;
      return Promise.reject(error);
    }
    return json;
  });
}
